<template>
	<div class="main">
		<div class="tit"><span>用户协议</span></div>

		<div class="mainBox">
			<h2>品牌客户用户协议</h2>
			<h6>1、导言</h6>
			<p>欢迎您使用由“时趣互动（北京）科技有限公司  ”（以下简称“公司”）合法运营并享有知识产权的“IP宇宙”。《品牌客户用户协议》（以下简称“本协议”）是您与公司之间就您下载、安装、注册、登录、发布信息、浏览、交流等方式使用（以下统称“使用”）“IP宇宙”提供的相关服务所订立的协议。</p>
			<p><strong>为了您可以更好享受“IP宇宙”提供的服务，在使用“IP宇宙”前，请您认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、法律适用和争议解决条款等。</strong>如您不接受本协议的条款或内容，您将无法享受完整的“IP宇宙”提供的产品和服务，您也可以选择停止使用；但<strong>若您使用了“IP宇宙”，您的使用行为将被视为您已认真阅读且透彻理解并完全同意本协议条款及“IP宇宙”的全部规定，且承诺遵守。</strong></p>
			<p><strong>若您未满18周岁，请在法定监护人的陪同下阅读本协议及本协议提及的相关协议，并在使用本服务前获得您法定监护人的书面同意。</strong></p>
			<h6>2、定义</h6>
			<p>2.1 帐号：指您在本网站开设并使用的帐号。</p>
			<p>2.2  IP宇宙/我们：指由众多IP资源方共同组成的信息分享、传播、交换平台，IP资源方通过手机、PC等多种移动终端接入，以短视频、图片、IP形象等多媒体形式分享自己的作品。</p>
			<p>2.3品牌用户/您：指希望通过IP宇宙的功能成功搜寻、浏览IP资源或者与IP资源方取得联系、达成合作的自然人或者法人。</p>
			<p>2.4 IP资源方：指呈现在IP宇宙中，合法持有或者运营IP资源并能够就该IP资源做出处分的自然人或者法人。</p>
			<p>2.5 项目：指通过借助IP宇宙的功能，由您与IP资源方达成合作的具体项目。</p>
			<p>2.6 跳单：指的是您基于IP宇宙的功能与IP资源方就具体项目达成了合作意向，但未能积极、诚实的配合IP宇宙的工作人员就项目内容进行沟通与回访，所涉内容如项目背景、项目需求、项目预估合作费用、项目合作期间等项目情况。 </p>
			<h6>3、协议的接受与确认</h6>
			<p>3.1 您可以通过搜索名称为 “IP宇宙”的网站（访问网址为：<a href="https://ip-yuzhou.com/">ip-yuzhou.com</a>  ）及标注名称为“IP宇宙”的客户端应用程序使用平台展示、发布、推广、交流等功能。</p>
			<p>3.2 <stron>为了您能更便捷的享受IP宇宙提供的服务，您需知悉并同意在注册账号过程中，同意本协议及与本协议密切相关的《隐私协议》，如您坚持并完成账号注册，后期使用账号的行为将受本协议与隐私政策的约束。另外，我们会不定期的对IP宇宙提供的服务进行更新或者改变，更新或者改变后如您继续使用IP宇宙，则视为您完全同意并理解更新或者改变后的IP宇宙及运行规则。</stron></p>
			<h6>4、账号注册与使用</h6>
			<p>4.1 <strong>您申请注册、登陆IP宇宙时，需要您提供并填写真实、准确、有效的注册信息，并按照要求设置账号密码。</strong>注册成功后，您申请的账号和密码是您享受IP宇宙服务的唯一合法凭证。<strong>您有责任对账号及密码进行定期维护，并对账号下的一切操作行为承担法律责任。</strong>一旦发现未经许可擅自使用您账号的情况，请您第一时间修改账号密码，并及时通知我们。</p>
			<p>4.2 您注册的帐号仅限于您本人使用，未经IP宇宙书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。<strong>如果出现前述情形，我们有权在未通知您的情况下，暂停或终止向该注册帐号提供服务，并有权注销该帐号，而无需向注册该帐号的用户承担法律责任。</strong></p>
			<p>4.3 为了符合国家的相关法律法规或政策，同时更好的享受IP宇宙提供的服务，在登陆、使用IP宇宙时，请您填写、提供信息材料（确保真实、有效），并确保所发布、传播的内容真实、合法。<strong>如您存在前述行为或者因您的前述行为引发纠纷的，我们有权拒绝为您提供服务，同时，如触犯法律，我们将移交司法、行政等权力机关进行相应处理，同时保留因您提供信息材料不真实、不合法给我们造成损失的权利。</strong></p>
			<p>4.4 <strong>特别提示您在使用IP宇宙时不得制作、上传、复制、发布、留言、传播或者转载如下内容：（1）反对宪法所确定的基本原则的；（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；（3）损害国家荣誉和利益的；（4）煽动民族仇恨、民族歧视，破坏民族团结的；（5）破坏国家宗教政策，宣扬邪教和封建迷信的；（6）散布谣言，扰乱社会秩序，破坏社会稳定的；（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；（8）侮辱或者诽谤他人，侵害他人合法权益的；（9）侵害未成年人合法权益或者损害未成年人身心健康的；（10）含有法律、行政法规禁止的其他内容的信息。</strong></p>
			<p>4.5 <strong>您理解，如您存在本协议4.4条的行为或者您制作、发布、复制、上传、留言、传播或者转载的内容侵犯他人知识产权或其他合法权益的，IP宇宙有权在接到通知后直接予以删除而无需经过您的同意，并且，IP宇宙有权配合权利人采取合法途径维护其自身权益。</strong>如您认为自身权益在IP宇宙遭到侵犯，请您第一时间联系我们，并提供相应的证据材料。我们会积极作出反馈并配合您维护自身权益。</p>
			<p>4.6 <strong>为提高项目需求曝光率、增加与IP资源方的合作几率，您同意将您在IP宇宙发布、上传、转发、评论的全部内容授权我们以同一账号自动发送至我们合法运营的其他平台、网站，您的账号之头像、昵称等公开信息亦将同步公开。您在IP宇宙账号的发布、修改、删除等操作也将同步反映在在前述平台、网站。</strong></p>
			<h6>5、个人信息保护</h6>
			<p>个人信息保护是我们不断前进的动力和坚守的底线，我们将与您一同致力于您个人信息（能够独立或与其他信息结合后识别、锁定您身份的信息）的保护。<strong>您在使用IP宇宙提供的服务时，需要您提供相应信息，我们将利用现代技术手段进行加密、匿名化等处理，更多保护措施请详见《隐私政策》。</strong></p>
			<h6>6、服务</h6>
			<p>6.1 IP宇宙提供的服务是我们根据现行政策及实际情况做出的，我们有权利保留变更、中断或终止部分或全部服务的权利。我们不承担因业务调整给您造成的损失。除非本协议另有其它明示规定，增加或强化目前IP宇宙的任何新功能，包括所推出的新产品，均受到本协议之规范。<strong>如您不接受服务调整，请停止使用本服务；否则，您的继续使用行为将被视为对服务调整的完全同意且承诺遵守。</strong></p>
			<p>6.2 <strong>IP宇宙对于提供的部分服务可能会收取一定的费用。</strong>在此情况下，我们在相关页面上将做明确的提示。如您拒绝支付该等费用，请您不要勾选相应的选项及相关操作，与此同时，您将无法使用相关的服务。</p>
			<p>6.3 为更好的实现IP宇宙运行的目标，我们将设置业务咨询通道及联系专线，为您解答在使用IP宇宙时遇到的各类问题。</p>
			<p>6.4 您理解，我们提供的服务仅限于现行IP宇宙的功能和开发程度，请您自行准备使用IP宇宙所需要的设备（电脑、手机等）及承担所需费用（如电费、网费等）。</p>
			<p>6.5 IP宇宙提供的服务内容可能包括：文字、软件、声音、图片、视频、图表、音乐等。所有这些内容受著作权、商标和其它财产所有权法律的保护。<strong>您只有在获得我们或其他相关权利人的授权之后才能使用这些内容，不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</strong></p>
			<p>6.6 请您理解和知悉，IP宇宙仅为线上传播、发布平台，当您通过IP宇宙发布、上传、留言、转播项目需求或者线索时，<strong>我们无法保证您一定能获取想要的IP资源、线索等信息，也无法保证您与IP资源方取得联系后一定能达成合作。</strong></p>
			<h6>7、行为规范</h6>
			<p>7.1 在您使用IP宇宙服务前，您需承诺：</p>
			<p>（1）您上传、发布的信息、作品必须符合国家法律法规和政策的要求，并同时符合IP宇宙的相关规定，不违法、违规或侵犯任何第三方的合法权益；</p>
			<p>（2）您的上传、发布行为和上传发布的信息、作品享有或已获得所必须的全部权利和授权，并不存在任何已有的或可能存在的侵权行为、纠纷或诉讼等；</p>
			<p>（3）您在使用IP宇宙服务的过程中，有可能获得IP宇宙的部分信息（包括但不限于公司信息、公司商业政策或其他任何信息等）。前述信息都属于我们的商业秘密，您不会将上述信息泄露、转让、有偿或无偿提供给第三方；</p>
			<p>（4）您承诺，您将合法合理地使用本服务，不会利用本服务从事违法、违规或侵权事宜，包括但不限于洗钱、赌博、套现或金融诈骗等。</p>
			<p>（5）您保证上传、发布的信息、作品、链接的安全性，不包含任何病毒、蠕虫、木马或类似损害程序代码的内容。</p>
			<p>7.2  <strong>您理解并同意，我们有权对您使用IP宇宙上传、发布的商业信息、项目需求、品牌logo、往期案例及使用行为等进行实时监控，</strong>如存在前述情况，我们有权在您提供合法有效的证明或其他合理解决方案之前对您上传、发布的信息、品牌介绍、案例等采取屏蔽、删除等处理措施或以单方通知的形式暂停或终止向您提供IP宇宙服务。如因此给我们造成任何损失，有权向您追偿。</p>
			<p>7.3 您理解，除非提前与我们达成协议，否则不得在IP宇宙上为与IP宇宙经营类别相同或者相近的第三方网站、产品或服务发布推广信息、广告产品/链接等。任何在IP宇宙发布的网页地址链接必须符合我们的要求，且仅能为展示您项目背景、需求、品牌历史等内容的网页链接。</p>
			<p>7.4 <strong>您同意并承诺，除非提前与我们达成协议，否则您不会自行或授权、允许、协助任何第三人对 IP宇宙提供相关服务中的数据信息内容进行如下任何行为：</strong></p>
			<p>（1）使用（包括但不限于复制、读取、采用）IP宇宙的服务及数据信息内容；</p>
			<p>（2）将IP宇宙提供服务中获取的数据信息内容以任何方式公示、提供、泄露给任何第三人或用于本协议之外的目的；</p>
			<p>（3）擅自编辑、整理、编排IP宇宙中获取的数据信息内容，用于其他平台、网站等渠道进行展示或使用；</p>
			<p>（4）以爬虫、蜘蛛、机器人等软件程序获得IP宇宙的数据。</p>
			<p>7.5 为更好的为您提供服务，您同意并承诺，<strong>凡在IP宇宙中查看、获取IP资源、取得IP资源方联系方式等信息的，需要您积极配合IP宇宙的工作人员进行项目沟通与回访，确保您提供的信息与内容真实、有效，不存在故意隐瞒、欺骗、虚假等情况。</strong></p>
			<h6>8、知识产权</h6>
			<p>8.1 公司是IP宇宙及本协议的知识产权权利人。IP宇宙服务中产生的一切著作权、商标权、专利权、商业秘密等知识产权，以及相关的所有信息内容（包括但不限于文字、图片、界面设计、版面框架等）均由公司享有，本协议另行约定、或按照相关法律法规规定由其他权利人享有的除外。</p>
			<p>8.2 您在IP宇宙上传、发布的信息、品牌logo、链接（含文字、图片、图像、音频、视频、图表、版面设计、logo、电子文档等）的知识产权及相关权利属于您所有。我们尊重您对于您所上传、发布的信息、作品、链接的相关知识产权权利，包括但不限于著作权、商标权等。</p>
			<p>8.3 <strong>您同意并理解，为持续改善IP宇宙的服务质量，您在IP宇宙上传、发布的信息、品牌logo、链接等内容（包括但不限于数据、图表、文字、图片、图像、音频、视频和/或音频中的音乐、声音、对话等）中您享有的部分或者全部知识产权，授权公司在全球范围内、免费、非独家、可再许可的权利（包括但不限于复制权、信息网络传播权、改编权、汇编权、修改权、翻译权、制作衍生品、表演和展示等权利），使用在公司运营维护的当前或其他网站、应用程序、产品或终端设备等，且适用于将来可能产生的途径使用。</strong></p>
			<h6>9、保密义务</h6>
			<p>9.1 您理解并同意，您和我们对本协议条款、技术信息、经营信息等信息负有同样的保密义务。保密信息包括但不限于IP宇宙接口技术、安全协议及证书等。</p>
			<p>9.2 您一旦选择使用IP宇宙，意味着您认可并同意签署本协议，任何一方在事先未经对方书面同意的情况下，不得向任何第三方披露、泄漏任何对方的保密信息；否则应当承担法律责任。</p>
			<p>9.3 <strong>无论何种原因导致您停止使用IP宇宙、注销账号等，您和我们对本协议条款、技术信息、经营信息等信息仍负有保密义务，保密期限不因任何原因而消除、中止、终结。</strong></p>
			<h6>10、免责声明</h6>
			<p>10.1 因您提供、上传的信息、品牌logo、链接等内容不符本协议要求，导致任何第三方向您进行的追索、诉讼等，我们不承担法律责任。尽管我们在您上传信息、品牌logo、链接等内容时会进行初步的审核，但是该审核行为并不免除您应承担的法律责任。此外，因您的行为导致公司因此陷入纠纷、诉讼的，您应积极采取处理措施，以避免公司因此卷入纠纷、承担损失。</p>
			<p>10.2 <strong>无论您以何种方式通过IP宇宙或者公司运营的其他平台、网站、渠道获取信息，最终与IP资源方达成项目合作，因此产生的任何争议、纠纷均与公司无关，您同意豁免我们（以及我们的员工、经理、代理人、分公司、合资企业和其他雇员）对从该纠纷中产生的所有类型的诉请和赔偿的全部责任。</strong> </p>
			<p>10.3 <strong>您在IP宇宙中使用第三方提供的软件及相关服务时，除需遵守本协议及其他相关规则外，还可能需要同意并遵守第三方的协议、相关规则。</strong>如因第三方服务产生的争议、损失或损害，由您自行与第三方解决，公司并不就此而对您或任何第三方承担任何责任。</p>
			<h6>11、违约责任</h6>
			<p>11.1 <strong>针对您违反本协议或其他规范的行为，公司有权独立判断并视情况采取预先警示、立即停止传输信息、限制账号部分或者全部功能直至永久关闭账号等措施。</strong></p>
			<p>11.2 <strong>因您违反本协议或者其他相关规范、协议导致IP资源方等第三方提起诉讼、索赔等，您应自行处理并承担全部可能由此引起的法律责任；如因您的侵权行为导致公司及其关联公司遭受损失的（包括经济、商誉等损失），您还应足额赔偿公司及其关联公司遭受的全部损失。   </strong></p>
			<h6>12、关于单项服务及第三方服务</h6>
			<p> 12.1 IP宇宙为您提供服务中可能涉及一些单项服务或者第三方服务，这些服务存在的目的是为您提供更好的服务体验及效果。公司有权对这些服务不时地增加、减少或改动。</p>
			<p>12.2 <strong>单项服务及第三方服务的开通需要通过您的同意，并且额外收取一定费用，需要您慎重考虑是否使用，一旦您开始使用上述服务，则视为您理解并接受有关单项服务的相关协议、规则的约束。</strong>  </p>
			<h6>13、其他</h6>
			<p>13.1 本协议的订立、效力、解释、适用、争议解决等一切事宜，均适用中华人民共和国大陆地区法律。</p>
			<p>13.2 因本协议产生的任何争议应友好协商解决，如无法解决或者无法达成一致的，任何一方有权向北京市朝阳区人民法院提起诉讼。</p>
			<p>13.3 <strong>为更好的为您服务及适应国家法律法规、政策调整，本协议、IP宇宙及其运行规则会不时的更新与调整。调整后的新版本我们也会在IP宇宙及时予以发布，如您继续使用IP宇宙，则视为您同意更新后的协议、IP宇宙及其运行规则。如您不同意或者有任何异议，请您立即停止登录或使用IP宇宙。如您不同意或者有异议，却仍继续使用IP宇宙，则视为您完全同意并接受。</strong></p>
			<p>13.4 您和公司均是独立的主体，在任何情况下本协议不构成公司对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。       </p>
			<p></p>
			<p></p>
		</div>

	</div>
</template>
<script type="text/javascript">
	export default {
		data() {
			return {

			}
		},
		created() {

		},
		components: {
		},
		methods: {


		}
	}
</script>
<style type="text/css" scoped="">
	.main {flex: 1;background: #FFFFFF;border-radius: 4px;padding: 20px 25px;margin-bottom: 30px;}
	.mainBox {color: #222;}
	.mainBox h2{font-size: 16px;padding: 20px 0;font-weight: bold;}
	.mainBox h6{font-size: 14px;padding: 20px 0;font-weight: bold;}
	.mainBox p{font-size: 14px;line-height: 26px;}
	.mainBox strong{font-weight: bold;}
	.mainBox p a{font-size: 14px;line-height: 26px;color: #222;text-decoration: underline;}
</style>
